import React from "react"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GenderHeader from "../components/storyline/genderHeader"
import GenderMid from "../components/storyline/genderMid"
import GenderBottom from "../components/storyline/genderBottom"
import Background from "../components/storyline/background"
import { graphql, useStaticQuery } from "gatsby"

const genderPage = css`
  display: flex;
  font-size: 0.2rem;
  flex-direction: column;
  position: relative;
`

const GenderPage = () => {
  const data = useStaticQuery(graphql`
    query {
      dataJson(slug: {eq: "storylines_gender"}) {
        ...StorylineContent
      }
    }
  `)
  const storylineContent = data.dataJson
  return (
    <Layout title="gender">
      <SEO title="THE WOMEN & MEN " />
      <div css={genderPage}>
        <Background storylineContent={storylineContent} />
        <GenderHeader storylineContent={storylineContent} />
        <GenderMid storylineContent={storylineContent}  />
        <GenderBottom
          storylineContent={storylineContent}
        />
      </div>
    </Layout>
  )
}

export default GenderPage
