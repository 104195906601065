import React from "react"
import { css } from "@emotion/core"
import Image from "../image"
import { useStaticQuery } from "gatsby"
import { breakpoint } from "./../../constants"
import { useViewport } from "./../../contexts/viewContext"
import PropTypes from "prop-types"
import AudioIcon from "../audioIcon"
import { graphql } from "gatsby"

const root = css`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  p {
    padding: 0;
    margin: 1.8rem 2rem 0 2rem;
    font-size: 0.6em;
    text-align: center;
  }
  @media (max-width: ${breakpoint}px) {
    padding: 0 0.3rem;
    box-sizing: border-box;
    p {
      box-sizing: border-box;
      font-size: 2.4em;
      margin: 0;
      width: 100%;
      margin: 1rem 0;
      padding: 0 0.4rem;
    }
  }
`

const background = css`
  width: 100vw;
  position: absolute;
  top: -0.8rem;
  left: 0;
  overflow: visible;
  z-index: -5;
`

const iconMan = css`
  position: absolute;
  width: 0.7rem;
  top: 0.4rem;
  left: 1rem;
  cursor: pointer;
  outline: none;
  @media (max-width: ${breakpoint}px) {
    position: static;
    width: 40vw;
    margin-top: 10rem;
    margin-bottom: 1rem;
  }
`
const iconWoman = css`
  position: absolute;
  width: 0.7rem;
  bottom: 0.4rem;
  right: 1rem;
  cursor: pointer;
  outline: none;
  @media (max-width: ${breakpoint}px) {
    position: static;
    width: 40vw;
    margin-bottom: 10.5rem;
    margin-top: 1rem;
  }
`


const GenderMid = ({ storylineContent, handleClick }) => {
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(filter: { extension: { eq: "mp3" } }) {
          edges {
            ...AudioURL
          }
        }
      }
    `
  )
  const nodeMan = allFile.edges.find(n => {
    return n.node.name === storylineContent.audioClipMan
  })
  const nodeWoman = allFile.edges.find(n => {
    return n.node.name === storylineContent.audioClipWoman
  })

  let urlMan = ""
  let urlWoman = ""
  if (nodeMan) {
    urlMan = nodeMan.node.publicURL
  } else {
    console.log(`${storylineContent.audioClipMan} can not been found!`)
  }
  if (nodeWoman) {
    urlWoman = nodeWoman.node.publicURL
  } else {
    console.log(`${storylineContent.audioClipWoman} can not been found!`)
  }

  const { width } = useViewport()
  let imgPath = ""
  width > breakpoint
    ? (imgPath = storylineContent.paperBlockImg.relativePath)
    : (imgPath = storylineContent.paperBlockImgMobile.relativePath)

  return (
    <div css={root}>
      <div css={background}>
        <Image filename={imgPath} />
      </div>
      <div css={iconMan} >
        <AudioIcon url={urlMan} audioFace={storylineContent.audioFaceMan} />
      </div>
      <p>{storylineContent.characterIntroWoman}</p>
      <div css={iconWoman} >
        <AudioIcon url={urlWoman} audioFace={storylineContent.audioFaceWoman} />
      </div>
    </div>
  )
}

GenderMid.propTypes = {
  storylineContent: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
}

export default GenderMid
